import { createSlice  } from "@reduxjs/toolkit";

const initialState = {data:[]}

const Actors = createSlice({
    name: 'Actors',
    initialState,
    reducers: {
      setActorData(state,action) {
console.log(action.payload)
        state.data = action.payload
        
      },
    },
  })
  
  export const { setActorData } = Actors.actions
  export default Actors.reducer