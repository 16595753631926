import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../Pages/Home/style.css";
import {logout} from '../../Redux/Store/Slices/SendData';
const DetailHeader = () => {

  //get data from redux
  const state = useSelector((state) => state);
  // console.log(state, "login");

  //get data from redux
  const disptach = useDispatch();

const navigate = useNavigate();

const Token = localStorage.getItem("accesstoken");
const Email = localStorage.getItem("email");

  const logouts = () => {
    localStorage.clear();
    disptach(logout())
    navigate('/')

  }



  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
        <Link class="navbar-brand" to="/">
          <div className="colorlib-navbar-brand d-flex justify-content-center  align-items-center">
          <h3 className="text-white mr-3">Bry </h3> {"    "}
          <h3 className="text-white">Talent</h3>
            {/* <img src={"/images/logo.png"} alt="logo" className="logo" /> */}
          </div>
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon intowhite"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mlauto ">
            <li class="nav-item active pr-5">
              <Link class="nav-link text-white" to="/">
                Home <span class="sr-only">(current)</span>
              </Link>
            </li>
            <li class="nav-item active pr-5">
              <Link class="nav-link text-white" to="/about">
                About Us <span class="sr-only">(current)</span>
              </Link>
            </li>
            {Token ? (
              <>
            <li class="nav-item active pr-5">
              <Link class="nav-link text-white" to="/createcasting">
                Create Casting <span class="sr-only">(current)</span>
              </Link>
            </li>
            <li class="nav-item active pr-5">
              <Link class="nav-link text-white" to="/casting">
              Casting List <span class="sr-only">(current)</span>
              </Link>
            </li>

              <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {state?.SendData?.email === null ? Email : state?.SendData?.email}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="btn btn-light" onClick={logouts}>Logout</button>
              </div>
            </div>
            </>
            ) : (
              <>
                <li class="nav-item pr-5">
                  <Link class="nav-link text-black" to="/login">
                    Login
                  </Link>
                </li>
                {/* <li class="nav-item pr-5">
                  <Link class="nav-link text-black" to="/register">
                    Register
                  </Link>
                </li> */}
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default DetailHeader;
