import { createSlice } from "@reduxjs/toolkit";

const initialState = {searchBy:"",data:[]}

const Search = createSlice({
    name: 'Search',
    initialState,
    reducers: {
      getSearchValue(state,action) {

          console.log(action ,"redux")
          console.log(state.data ,"state.data")
        state.searchBy = action.payload.searchBy
          state.data = [...action.payload.data]
      },
    },
  })
  
  export const { getSearchValue } = Search.actions
  export default Search.reducer