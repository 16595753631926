import React from "react";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Header from "../../../components/Header/Header";
import "./About.css";

const About = () => {
  return (
    <>
      <section
        class=""
        // style={{
        //   backgroundImage: "url('/images/bg-home-1.jpeg')",
        //   backgroundPositionY: "60px",
        //   backgroundSize: "auto",
        // }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div className="container">
          <Header />
        </div>
        {/* <div class="container">
          <div
            class="row no-gutters text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div class="col-md-8 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/" className="text-dark">
                    Home
                  </a>
                </span>{" "}
                <span class="mr-2 text-dark">About</span>{" "}
                <span className="text-dark">About</span>
              </p>
              <h1 class="mb-5 bread text-dark">About</h1>
            </div>
          </div>
        </div> */}
      </section>
      {/* <!-- END slider --> */}

      <div className="container my-5">
        <div className="video-background">
          <video autoPlay loop muted>
            <source src={"/images/file.mp4"} type="video/mp4" />
          </video>
          <div className="overlay-content">
            <h1>T h e   a g e n c y  </h1>
            <p>
              Bry Talent is the ultimate booking agency with over 100 clients
              and an incredible range of diverse talent from all ages,
              ethnicities, genders and sexes! We work with union, non-union, and
              double carded actors to help you find the perfect match for your
              project. Our team is committed to upholding our values of respect,
              transparency and communication, and we are dedicated to promoting
              Diversity, Equity and Inclusion in everything we do.We put all
              the necessary means to achieve clients’ visions and goals.
            </p>
          </div>
        </div>
      </div>
      <div className="container para">
        <h1 className="mt-5">B r y (s h e / h e r)</h1>
        <h6>
            Has worked in the industry for over eight years and has a passion
          for the commercial industry. At Bry our clients and talent always come
          first, building lasting relationships between actors and production
          companies. If you are looking for talent for your production, you can
          browse Bry Talent’s online roster. Want to learn more ? Give us a
          shout- we’d love to chat.
        </h6>
      </div>
      <div className="container about">
        <div className="contact">
          <div className="overylay">
            <div className="row">
              <div className="col-md-4">
                <h4>Call Us</h4>
                <h4>098765432</h4>
              </div>
              <div className="col-md-4">
                <h4>Email</h4>
                <h4>Brytalent@support.com</h4>
              </div>
              <div className="col-md-4">
                <h4>Subscribe</h4>
                <div className="row">
                  <div className="col-md-2 col-2"></div>
                  <div className="col-md-2 col-2">
                    <FaFacebookF style={{ fontSize: "20px" }} />
                  </div>
                  <div className="col-md-2 col-2">
                    <AiOutlineTwitter style={{ fontSize: "20px" }} />
                  </div>
                  <div className="col-md-2 col-2">
                    <AiFillLinkedin style={{ fontSize: "20px" }} />
                  </div>
                  <div className="col-md-2 col-2">
                    <BsInstagram style={{ fontSize: "20px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
