import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DetailHeader from "../../../components/Header/DetailHeader";
import { Base_Api } from "../../../config";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";
import { BsFillTrashFill } from "react-icons/bs";
import LoadingOverlay from "react-loading-overlay";
import { Vortex } from "react-loader-spinner";

const Casting = () => {
  const [loader, setLoader] = useState(false);
  const [Data, setData] = useState();
  const [, setErrors] = useState(null);
  const [castinglist, setCastingList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalid, setModalid] = useState("");
  const [Desc, setDesc] = useState("");
  const [CastingId, setCastingId] = useState("");
  const [ClientName, setClientName] = useState("");
  const [CastingDirector, setCastingDirector] = useState("");
  const [Title, setTitle] = useState("");
  const [S_Date, setS_date] = useState("");
  const [E_Date, setE_date] = useState("");
  const [Summary, setSummary] = useState("");
  const [Castingid, setCastingid] = useState();
  const [Behalf, setBehalf] = useState([]);
//   const [EditCasting, setEditCasting] = useState();
    console.log(Castingid, "Castingid");
  //casting List api
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("accesstoken");

  const castingList = useCallback(() => {
    setIsActive(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/user/castinglist/${user_id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setIsActive(false);
        console.log(response, "details");
        setBehalf(response?.data?.data?.filter((e)=>e?.behalf !== null));
        setData(response?.data?.data, "Details");
      })
      .catch(function (error) {
        setIsActive(false);

        console.log(error);
      });
  }, [token, user_id]);
  console.log("Details", Data);


  // all user casting list
  const getAllCastingList = async () => {
    setErrors(null);
    setIsActive(true);
    try {
      var config = {
        method: "get",
        url: `${Base_Api}/api/user/castinglist/${user_id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      setIsActive(false);
      console.log(response, "user castinglist");
      console.log(response?.data?.data?.filter((e)=>e?.behalf == null), "user castinglist");
      setCastingList(response?.data?.data?.filter((e)=>e?.behalf === null));
    } catch (error) {
      setIsActive(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        setIsActive(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  // delete casting list
  const delcasting = async (id) => {
    setErrors(null);
    setIsActive(true);
    try {
      var config = {
        method: "delete",
        url: `${Base_Api}/api/user/casting_lists/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      console.log(response, "castinglist delet");

      if (response?.data?.status === 1) {
        setIsActive(false);
        toast.success("casting list delete successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        window.location.reload();
      }
    } catch (error) {
      setIsActive(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        setIsActive(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  //contact admin
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const contactapi = () => {
    setLoader(true);


 const user_id = localStorage.getItem("user_id")   
    const contactdata = new FormData();
    contactdata.append("description", Desc);
    contactdata.append("user_id", user_id);

    var config = {
      method: "post",
      url: `${Base_Api}/api/user/actor_contact/${modalid}`,
      data: contactdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "contact api");
        toast.success("message Sent Successfully");
        setShow(false);
        // setDetails(response, "Details");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        console.log(error);
      });
  };


   //Edit api casting list 
  const handleModalClose = () => setModal(false);
  const handleModal = (id) => {
    setModal(true)
    editShowcastingapi(id);
};

  const editShowcastingapi = (id) => {
    setLoader(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/user/casting_lists/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "contact api");
        setCastingId(response?.data?.data?.id);
        setClientName(response?.data?.data?.client_name);
        setCastingDirector(response?.data?.data?.casting_director);
        setTitle(response?.data?.data?.title);
        setS_date(response?.data?.data?.start_date);
        setE_date(response?.data?.data?.end_date);
        setSummary(response?.data?.data?.summary);
        // toast.success("message Sent Successfully");
        // setShow(false);
        // setDetails(response, "Details");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        console.log(error);
      });
  };

  const editcastingapi = (id) => {
    setLoader(true);

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_name", ClientName);
    urlencoded.append("casting_director", CastingDirector);
    urlencoded.append("title", Title);
    urlencoded.append("start_date", S_Date);
    urlencoded.append("end_date", E_Date);
    urlencoded.append("summary", Summary);
    var config = {
      method: "put",
      url: `${Base_Api}/api/user/casting_lists/${CastingId}`,
      data: urlencoded,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "contact api");
        if(response?.data?.status === true)
        setModal(false);
        toast.success("Casting List Update Successfully");
        getAllCastingList();
        // setDetails(response, "Details");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        console.log(error);
      });
  };


  useEffect(() => {
    castingList();
  }, [castingList]);

  useEffect(() => {
    getAllCastingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
console.log(Behalf)
  return (
    <>
      <section
        class="hero-wrap"
        style={{ backgroundImage: "url('/images/bg_4.jpg')" }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <DetailHeader />
        <div class="container">
          <div
            class="row no-gutters text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div class="col-md-12 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">Home</a>
                </span>{" "}
                <span class="mr-2">Casting</span> <span>Casting List</span>
              </p>
              <h1 class="mb-5 bread">Casting List</h1>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END slider --> */}

      <section className="ftco-section-2">
        <div className="container-fluid">
          <div className="row d-flex no-gutters">
            <div className="col-md-12 d-flex align-items-center justify-content-center heading-section">
              <div className="text-center">
                <div className="p-5">
                  <h2 className="mb-4">Admin Casting List</h2>
                </div>
              </div>
            </div>

            <div className="container">
              <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
              >
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Casting Name</th>
                      {/* <th>Id</th> */}
                      <th>Casting Director</th>
                      <th>Summary</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Behalf?.map((e) => (
                      <>
                        <tr>
                          <td>{e?.title}</td>
                          <td>{e?.casting_director}</td>
                          <td>{e?.summary}</td>
                          <td className="text-center d-flex justify-content-around">
                            <Link to={"/castingdetail/" + e?.id}>
                              <AiFillEye
                                style={{
                                  height: "2em",
                                  width: "2em",
                                  color: "white",
                                }}
                              />
                            </Link>
                            {/* <button
                              className="btn btn-light"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => {
                                handleShow();
                                setModalid(e?.id);
                              }}
                            >
                              Send Email
                            </button> */}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </LoadingOverlay>

              <div className="col-md-12 d-flex align-items-center justify-content-center heading-section">
                <div className="text-center">
                  <div className="p-5">
                    <h2 className="mb-4">Your Casting List</h2>
                  </div>
                </div>
              </div>
              <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
              >
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      {/* <th>Id</th> */}
                      <th>Casting Name</th>
                      <th>Casting Director</th>
                      <th>Summary</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {castinglist?.map((e) => (
                      <>
                        <tr>
                          {/* <td>{e?.id}</td> */}
                          <td>{e?.title}</td>
                          <td>{e?.casting_director}</td>
                          <td>{e?.summary}</td>
                          <td className="text-center d-flex justify-content-around">
                            <Link to={"/castingdetail/" + e?.id}>
                              <AiFillEye
                                style={{
                                  height: "2em",
                                  width: "2em",
                                  color: "white",
                                }}
                              />
                            </Link>
                            <div onClick={()=>{
                                // alert("")
                                handleModal(e?.id)
                                setCastingid(e?.id);
                                }}>
                              <AiFillEdit
                                style={{
                                  height: "2em",
                                  width: "2em",
                                  color: "white",
                                }}
                              />
                            </div>
                            <div
                              className=""
                              onClick={() => {
                                delcasting(e?.id);
                              }}
                            >
                              <BsFillTrashFill
                                style={{
                                  height: "2em",
                                  width: "2em",
                                  color: "red",
                                }}
                              />
                            </div>
                            <button
                              className="btn btn-light"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => {
                                handleShow();
                                setModalid(e?.id);
                              }}
                            >
                              Send Email
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Modal --> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Message to Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Message</label>
          <textarea
            className="from-control"
            placeholder="Send message to actor"
            rows={8}
            cols={60}
            onChange={(e) => setDesc(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={contactapi}>
            {loader === true ? (
              <>
                <Vortex
                  visible={true}
                  height="50"
                  width="80"
                  ariaLabel="vortex-loading"
                  wrapperStyle={{}}
                  wrapperClass="vortex-wrapper"
                  colors={["white", "black"]}
                />
              </>
            ) : (
              <>Send</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Modal --> */}

      <Modal show={modal} onHide={handleModalClose} className="editmodal">
        <LoadingOverlay active={loader} spinner text="Loading your content...">
          <Modal.Header>
            <Modal.Title>Edit Casting List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <label className="mt-2">Client Name</label>
                <input
                  type={"text"}
                  className="form-control"
                  value={ClientName}
                  placeholder="Enter Client Name"
                  onChange={(e) => setClientName(e.target.value)}
                  />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Casting Director</label>
                <input
                  type={"text"}
                  className="form-control"
                  value={CastingDirector}
                  placeholder="Enter Casting Director"
                  onChange={(e) => setCastingDirector(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Title</label>
                <input
                  type={"text"}
                  className="form-control"
                  value={Title}
                  placeholder="Enter Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Start Date</label>
                <input
                  type={"date"}
                  className="form-control"
                  value={S_Date}
                  placeholder="Enter Start Date"
                  onChange={(e) => setS_date(e.target.value)}
                />
              </div>
              <div className="col-12 Col-md-6">
                <label className="mt-2">End Date</label>
                <input
                  type={"date"}
                  className="form-control"
                  value={E_Date}
                  placeholder="Enter End Date"
                  onChange={(e) => setE_date(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-12">
                <label className="mt-2">Summary</label>
                <textarea
                  rows={5}
                  type={"text"}
                  className="form-control"
                  value={Summary}
                  placeholder="Enter Summary"
                  onChange={(e) => setSummary(e.target.value)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={editcastingapi}
            >
              Send
            </Button>
          </Modal.Footer>
        </LoadingOverlay>
      </Modal>
    </>
  );
};

export default Casting;
