import React, { useCallback, useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
// Import Swiper React components


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules

import Header from "../../components/Header/Header";
import {useNavigate } from "react-router-dom";
import { Base_Api } from "../../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getSearchValue } from "../../Redux/Store/Slices/SearchData";

const Home = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const token = localStorage.getItem("accesstoken");
  const userid = localStorage.getItem('user_id')
  const disptach = useDispatch();

  const [, setLoader] = useState(false);
  const [Casting, setCasting] = useState();
  const [CastingId, setCastingId] = useState();
  const [actorName, setActorName] = useState("");
  const [,setSearchResult] = useState();
  const [actorData, setActorData] = useState();
  const navigate = useNavigate();

  console.log(CastingId, "CastingId");
  console.log(actorName, "ActorName");


  const castinglist = useCallback(() => {
    setLoader(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/user/castinglist/${userid}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "checking");
        setCasting(response?.data?.data);
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  },[token, userid])
  console.log("castinglist", Casting);

  useEffect(() => {
    castinglist();
  }, [castinglist]);

  const search = () => {
    var config = {
      method: "get",
      url: `${Base_Api}/api/user/search?${actorName !== undefined && `actor=${actorName}`} & ${CastingId !== undefined && `casting_list=${CastingId}` }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "checking");
        localStorage.setItem("castinglist_id", CastingId);
        setSearchResult(response?.data?.data);
        if(actorName && CastingId){
          disptach(getSearchValue({searchBy:"both",data:response?.data?.data}));

        }
        else if(actorName){
          disptach(getSearchValue({searchBy:"actor",data:response?.data?.data}));
        }
        else if(CastingId) {
          disptach(getSearchValue({searchBy:"casting",data:response?.data?.data}));
        
        }
        
          navigate(`/searchresult?castingid=${CastingId}`);
        
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  };

  console.log(Casting)
  const ActorApi = useCallback(() => {
    setLoader(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/feature/actors`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "sActorData");
        setActorData(response?.data?.data);
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  },[token])

  useEffect(() => {
    ActorApi();
  }, [ActorApi]);

  const state = useSelector((state) => state);
  console.log(state, "home");

  return (
    <>
      <div className="page">
        <div id="colorlib-page">
          <header>
            <div className="row">
              <div className="col-lg-12">
                <Header />
              </div>
            </div>
          </header>
          <div className="bg-home">
            {token ? (
              <div className="search d-flex justify-content-center align-items-center custom_height">
                <div className="custom_container">
                  <div className="row p-5 mt-sm-20">
                    <div className="col-lg-5 p-0 border-0 w-100 mt-2 mb-md-2">
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Enter Model Name"
                        onChange={(e) => setActorName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-5  mt-2 p-0 mb-md-2">
                      <select
                        class="custom-select custom-select-lg border-0 rounded-0"
                        onClick={() => castinglist()}
                        onChange={(e) => setCastingId(e.target.value)}
                      >
                        <option selected>Select Casting List</option>
                        {Casting?.map((e) => <> <option value={e?.id}>{e?.title}</option>  </> )}
                      </select>
                    </div>
                    <div className="col-lg-2 p-0 mt-2 mt-md-0">
                      <button
                        className="btn btn-dark search_btn mt-2"
                        onClick={search}
                      >
                        search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="name d-flex justify-content-center align-items-center flex-column"
                style={{ height: "70em" }}
              >
                <h1 className="text-dark text-sm-center" style={{letterSpacing: "20px"}}>BRY TALENT</h1>
                <h1 className="text-banner" style={{fontWeight: "bold"}}>Booking Agency</h1>
              </div>
            )}
          </div>
          {/* <h1>scnskcnsk</h1> */}
          {token ? (
            <section className="ftco-section-2">
              <div className="container-fluid">
                <div className="row d-flex no-gutters">
                  <div className="col-md-6 d-flex align-items-center  heading-section bg-light">
                    <div className="col-md-6">
                      <div className="p-5">
                        <h2 className="mb-4">Our Tops Talent</h2>
                      
                      </div>
                    </div>
                  </div>
                  
                  {actorData?.map((e)=>{
                    return(
                    <>
                  <div className="col-md-3 model-entry  p-md-0" onClick={()=>navigate(`/actorDetails/${e?.id}`)}>
                    <div
                      className="model-img"
                      // style={{ backgroundImage: `url(${e?.images[0]?.image})`}}
                      // alt="no image found"
                    >
                      <img src={e?.images[0]?.image} alt="no internet" />
                      <div className="name">
                        <h3 className="text-dark">{e?.full_name}</h3>
                      </div>
                      <div className="text">
                        <h3 className="text-white">{e?.full_name}</h3>
                        <div className="d-flex models-info">
                          <div className="pr-md-3">
                            <p>Height</p>
                            <span>{e?.height}</span>
                          </div>
                          <div className="pr-md-3">
                            <p>Sex</p>
                            <span>{e?.gender}</span>
                          </div>
                          <div className="pr-md-3">
                            <p>Weight</p>
                            <span>{e?.weight}</span>
                          </div>
                          <div className="pr-md-3">
                            <p>Eye Color</p>
                            <span>{e?.eye_color}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                    )
                  })}
                </div>
              </div>
            </section>
          ) : null}

          <section className="bg-white">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="row my-5  w-100 d-flex justify-content-center align-items-center">
                    <div className="col-4 col-md-2">
                      <img
                        src="/images/image1.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <img
                        src="/images/image2.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <img
                        src="/images/image3.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <img
                        src="/images/image4.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-4 col-md-2">
                      <img
                        src="/images/image5.jpeg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 w-100 d-flex justify-content-center align-items-center">
                  <h1 className="text-center">Working With the Best <br/>Clients and Partners</h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Home;
