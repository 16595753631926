import React from 'react';
import {Carousel} from 'react-bootstrap';


const TableSlider = ({images}) =>(
		
		<Carousel prevIcon={<span aria-hidden="true" className="fas fa-chevron-left text-black" />}
				nextIcon={<span aria-hidden="true" className="fas fa-chevron-right text-black" />}
		>
				{images.map((image) =>  <Carousel.Item>
						<img src={image.image} className="d-block w-100" alt="..." />
					</Carousel.Item>
                )}
					
		</Carousel>
			
	)

export default TableSlider;