import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useState } from "react";
import { useSelector } from "react-redux";
import DetailHeader from "../components/Header/DetailHeader";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",

    padding: 10,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  column: {
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  col1: {
    flexBasis: "8.33%",
  },
  col2: {
    flexBasis: "16.66%",
  },
  col3: {
    flexBasis: "25%",
  },
  col4: {
    flexBasis: "33.33%",
  },
  col5: {
    flexBasis: "41.66%",
  },
  col6: {
    flexBasis: "50%",
  },
  col7: {
    flexBasis: "58.33%",
  },
  col8: {
    flexBasis: "66.66%",
  },
  col9: {
    flexBasis: "75%",
  },
  col10: {
    flexBasis: "83.33%",
  },
  col11: {
    flexBasis: "91.66%",
  },
  col12: {
    flexBasis: "100%",
  },
  cell: {
    padding: 5,
    backgroundColor: "#FFFFFF",
  },
  dflex: {
    display: "flex",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row",
  },
  alignContentCenter: {
    alignContent: "center",
  },
  alignContentFlexStart: {
    alignContent: "flex-start",
  },
  alignContentFlexEnd: {
    alignContent: "flex-end",
  },
  alignContentSpaceBetween: {
    alignContent: "space-between",
  },
  alignContentSpaceAround: {
    alignContent: "space-around",
  },
  alignContentSpaceEvenly: {
    alignContent: "space-evenly",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  justifyContentFlexStart: {
    justifyContent: "flex-start",
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between",
  },
  justifyContentSpaceAround: {
    justifyContent: "space-around",
  },
  justifyContentSpaceEvenly: {
    justifyContent: "space-evenly",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  alignItemsFlexStart: {
    alignItems: "flex-start",
  },
  alignItemsFlexEnd: {
    alignItems: "flex-end",
  },
  alignItemsStretch: {
    alignItems: "stretch",
  },

  viewer: {
    width: window.innerWidth / 1.03, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// Create Document Component
function BasicDocument() {
  const data = useSelector((state) => state.ActorData.data);

  const [layoutType, setLayoutType] = useState(1);
  function convertHeightToFeet(height) {
    var totalInches = parseFloat(height);
    var feet = Math.floor(totalInches / 12);
    var inches = Math.floor(totalInches % 12);
    return feet + " ft " + inches + " in";
  }
  const convertToBlobURL = async (base64Link) => {
    const response = await fetch(base64Link);
    const blob = await response.blob();
  return URL.createObjectURL(blob);
   
  };


  return (
    <>
      <div className="page">
        <div id="colorlib-page">
          <div style={{ background: "#1f1f1c29" }}>
            <DetailHeader />
          </div>

          <div className="container-fluid">
            <div className="d-flex justify-content-center align-item-center my-3">
              <button
                className={`${
                  layoutType === 1 ? "btn btn-dark" : "btn btn-primary"
                } `}
                onClick={() => setLayoutType(1)}
              >
                1 Actor Per Page
              </button>
              <button
                className={`${
                  layoutType === 2 ? "btn btn-dark" : "btn btn-primary"
                } `}
                onClick={() => setLayoutType(2)}
              >
                2 Actor Per Page
              </button>
              <button
                className={`${
                  layoutType === 4 ? "btn btn-dark" : "btn btn-primary"
                } `}
                onClick={() => setLayoutType(4)}
              >
                4 Actor Per Page
              </button>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <PDFViewer style={styles.viewer}>
                  {/* Start of the document*/}
                  <Document>
                    {/*render a single page*/}
                    {layoutType === 1 && (
                      <>
                        {/* start single page layout */}
                        {data.map((e) => {
                          return (
                            <Page size="A4" style={[styles.page,{display:"flex",alignContent:"center",justifyContent:"center"}]}>
                              <View style={[{padding:"20px"}]}>
                                <View style={[styles.row]}>
                                  <View style={styles.col4}>
                                 
                                    {e.images.length > 0 ? (
                                        <Image
                                        src={convertToBlobURL(e.images[0].baseImage)}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                        }}
                                      />
                                    ) : (
                                      <Image
                                        src={`/images/unkown.jpg`}
                                        style={{
                                          width: "100%",
                                          height: "80%",
                                        }}
                                      />
                                    )}
                                  </View>
                                  <View
                                    style={{
                                      ...styles.col6,
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {e.fname} {e.lname}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Gender: {e.gender.toUpperCase()}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Age Range: {e.age_range_from} -{" "}
                                      {e.age_range_to}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Height: {convertHeightToFeet(e.height)}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Weight:{e.weight} LBS{" "}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </Page>
                          );
                        })}
                        {/* end single page layout */}
                      </>
                    )}
                    {layoutType === 2 && (
                      <>
                        {/* start double page layout */}
                        <Page size="A4" style={[styles.page,{display:"flex",alignContent:"center",justifyContent:"center"}]}>
                          <View style={[ ]}>
                            {data.map((e) => {
                              return (
                                <View style={[styles.row,{marginTop:"20%"}]}>
                                  <View style={styles.col4}>
                                    {e.images.length ? (
                                      <Image
                                        src={convertToBlobURL(e.images[0].baseImage)}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                        }}
                                      />
                                    ) : (
                                      <Image
                                        src={`/images/unkown.jpg`}
                                        style={{
                                          width: "100%",
                                          height: "80%",
                                        }}
                                      />
                                    )}
                                  </View>
                                  <View
                                    style={{
                                      ...styles.col6,
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {e.fname} {e.lname}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Gender: {e.gender.toUpperCase()}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Age Range: {e.age_range_from} - {" "}
                                      {e.age_range_to}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Height: {convertHeightToFeet(e.height)}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Weight:{e.weight} LBS{" "}
                                    </Text>
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        </Page>
                        {/* end double page layout */}
                      </>
                    )}
                    {layoutType === 4 && (
                      <>
                        {/* start Four in a page layout */}
                        <Page size="A4" style={[styles.page,{display:"flex",alignContent:"center",justifyContent:"center",alignItems:"center"}]}>
                          <View style={[styles.row,{flexWrap:"wrap",margin:"20px"}]}>
                            {data.map((e,i) => {
                              return (
                                <View style={[styles.col6,{marginTop: i <=1? "10px":"100px"}]}>

                                <View style={[styles.row]}>
                                  <View style={styles.col6}>
                                    {e.images.length > 0 ? (
                                      <Image
                                        src={convertToBlobURL(e.images[0].baseImage)}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                        }}
                                      />
                                    ) : (
                                      <Image
                                        src={`/images/unkown.jpg`}
                                        style={{
                                          width: "100%",
                                          height: "200px",
                                        }}
                                      />
                                    )}
                                  </View>
                                  <View
                                    style={{
                                      ...styles.col6,
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      {e.fname} {e.lname}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Gender: {e.gender.toUpperCase()}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Age Range: {e.age_range_from} -{" "}
                                      {e.age_range_to}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Height: {convertHeightToFeet(e.height)}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      Weight:{e.weight} LBS{" "}
                                    </Text>
                                  </View>
                                </View>
                                </View>
                              );
                            })}
                          </View>
                        </Page>
                        {/* end Four in a page layout */}
                      </>
                    )}
                  </Document>
                </PDFViewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BasicDocument;
