import { createSlice } from '@reduxjs/toolkit'

const initialState = { token: null, auth_token: null, email: null, firstName: null, lastName: null, userName: null}
    

const auth = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    getAuthValue(state,action) {
        console.log(action,state ,"redux")
      state.token = action.payload.access_token
      state.auth_token = action.payload.auth_token
      state.email = action.payload.email
      state.firstName = action.payload.fname
      state.lastName = action.payload.lname
      state.userName = action.payload.username      
    },
    logout(state,action) {
        console.log(action,state ,"redux")
      state.token = null
      state.auth_token = null
      state.email = null
      state.firstName = null
      state.lastName = null
      state.userName = null
    },
  },
})

export const { getAuthValue, logout } = auth.actions
export default auth.reducer