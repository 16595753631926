/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../Home/style.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { setSession } from "../../../../utlilites/jwt";
import axios from "../../../../utlilites/axios";
import { useDispatch, useSelector } from "react-redux";
import { getAuthValue } from "../../../../Redux/Store/Slices/SendData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Vortex } from "react-loader-spinner";

function Login() {
  const disptach = useDispatch();

  const state = useSelector((state) => state);
  console.log(state, "loginssss");

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .required("Required"),
  });

  const Navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [, getUserData] = useState();

  const landlogin = async (V) => {
    setLoader(true);
    try {
      const response = await axios.post("/api/login", V);
    ;
      console.log(response, "working");
      const { status, token } = response.data;
      console.log(token, "loginq");
      if (status === true) {
        setLoader(false);
        getUserData(response?.data?.data);
        disptach(getAuthValue(response.data.data));
        localStorage.setItem("email", response?.data?.data?.email);
        localStorage.setItem("user_id", response?.data?.data?.id)
        setSession(response?.data?.data?.access_token);
        toast.success("Login Successfully");
        Navigate("/");
      } else {
        toast.error(response.data.error);

        setLoader(false);

      }
    } catch (error) {
      setLoader(false);
      console.log(error?.message);
      toast.success(error?.message);
    }
  };

  return (
    <>
      <section className="vh-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-black">
              <div className="px-5 ms-xl-4">
                <i
                  className="fas fa-crow fa-2x me-3 pt-5 mt-xl-4"
                  style={{ color: "#709085" }}
                ></i>
                <Link to={"/"} className="d-flex justify-content-start  align-items-center">
                  {/* <img src={"/images/logo1.png"} alt="logo" className="logo" /> */}
                  <h3 className=" mr-3">Bry</h3>  {" "}
                   <h3 className="">Talent</h3>
                </Link>
              </div>

              <div className="d-flex align-items-center mt-5 px-5 ms-xl-4  pt-xl-0 mt-xl-n5">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    console.log(values);
                    // same shape as initial values

                    landlogin(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form style={{ width: "23rem" }}>
                      <h3
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: "1px" }}
                      >
                        Log in
                      </h3>

                      <div className="form-outline mb-4">
                        <Field
                          name="email"
                          type="email"
                          id="form2Example18"
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" for="form2Example18">
                          Email address
                        </label>
                      </div>

                      <div className="form-outline mb-4">
                        <Field
                          name="password"
                          type="password"
                          id="form2Example28"
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" for="form2Example28">
                          Password
                        </label>
                      </div>

                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="submit"
                        >
                          {loader === true ? (
                            <>
                              <Vortex
                                visible={true}
                                height="50"
                                width="80"
                                ariaLabel="vortex-loading"
                                wrapperStyle={{}}
                                wrapperClass="vortex-wrapper"
                                colors={[
                                  "white",
                                  "black",
                                ]}
                              />
                            </>
                          ) : (
                            <>Login</>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-sm-6 px-0 d-none d-sm-block">
              <img
                src="/images/image_5.jpg"
                alt="Login image"
                className="w-100 "
                style={{
                  objectFit: "cover",
                  objectPosition: "top center",
                  height: "100vh",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
