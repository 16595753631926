/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { toast } from "react-toastify";
import { Alert, Button, Modal } from "react-bootstrap";
import Select from "react-select";
// import { Range, getTrackBackground } from "react-range";
// import PageTitle from "../../layouts/PageTitle";
import { CSVLink } from "react-csv";
import TableSlider from "./TableSlider";
import "./style.css";
import DetailHeader from "../../../components/Header/DetailHeader";
import { Base_Api } from "../../../config";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import TooltipSlider from "../../../components/ToolTipSlider";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActorData } from "../../../Redux/Store/Slices/ActorData";

// const GlobalFilter = ({ filter, setFilter }) => (
//   <div>
//     Search :{" "}
//     <input
//       className="ml-2 input-search form-control"
//       value={filter || ""}
//       onChange={(e) => setFilter(e.target.value)}
//       style={{ width: "20%" }}
//     />
//   </div>
// );
const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

// function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });

//     return [...options.values()];
//   }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     <select
//       className="form-control input-search"
//       value={filterValue}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//       // prevent sorting on filter click
//       onClick={(e) => e.stopPropagation()}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }

function EthinicitySelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
 
  // Render a multi-select box
  return (
    <select
      className="form-control input-search"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>
      <option value="african american">african american</option><option value="american indian">american indian</option><option value="asian">asian</option><option value="caucasian">caucasian</option><option value="east indian">east indian</option><option value="eastern european">eastern european</option><option value="hispanic">hispanic</option><option value="mediterranean">mediterranean</option><option value="mixed">mixed</option><option value="pacific islander">pacific islander</option>
    </select>
  );
}
function GenderSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {


  // Render a multi-select box
  return (
    <select
      className="form-control input-search"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>

      <option value='male'>
        male
      </option>
      <option value='female'>
        female
      </option>
    </select>
  );
}

function eyeColorSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
 


  // Render a multi-select box
  return (
    <select
      className="form-control input-search"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>

      <option value="black">black</option><option value="blue">blue</option><option value="brown">brown</option><option value="green">green</option><option value="hazal">hazal</option>
    </select>
  );
}
function hairColorSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {

  return (
    <select
      className="form-control input-search"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>
      <option value="auburn">auburn</option><option value="bald">bald</option><option value="black">black</option><option value="blonde">blonde</option><option value="blue">blue</option><option value="brown">brown</option><option value="red">red</option><option value="salt  &amp; pepper">salt &amp; pepper</option><option value="sliver">sliver</option><option value="white">white</option>
    </select>
  );
}
function unionsSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {

  return (
    <select
      className="form-control input-search"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // prevent sorting on filter click
      onClick={(e) => e.stopPropagation()}
    >
      <option value="">All</option>
      <option value="double carded" className="text-uppercase">double carded</option><option value="ACTRA">ACTRA</option><option value="ACTRA Apprentice">ACTRA Apprentice</option><option value="Non-Union">Non-Union</option></select>
  );
}

const convertHeightToInches = (height) => {
  const [feet, inches = 0] = height.toString().split('.').map(Number);
  return feet * 12 + inches;
};


function convertToFeetInches(heightInInches) {
  var totalInches = Math.round(heightInInches);
  var feet = Math.floor(totalInches / 12);
  var inches = totalInches % 12;
  return feet + " ft " + inches + " in";
}

function HeightRangeColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max values of the column
  // eslint-disable-next-line
  const [min, max] = React.useMemo(() => {
    const columnValues = preFilteredRows.map((row) => row.values[id]);
    return [Math.min(...columnValues), Math.max(...columnValues)];
  }, [id ,preFilteredRows]);

  // Update the filter value when the slider value changes
  const marks = {
    49: "4'1\"",
    50: "4'2\"",
    51: "4'3\"",
    52: "4'4\"",
    53: "4'5\"",
    54: "4'6\"",
    55: "4'7\"",
    56: "4'8\"",
    57: "4'9\"",
    58: "4'10\"",
    59: "4'11\"",
    60: "5'0\"",
    61: "5'1\"",
    62: "5'2\"",
    63: "5'3\"",
    64: "5'4\"",
    65: "5'5\"",
    66: "5'6\"",
    67: "5'7\"",
    68: "5'8\"",
    69: "5'9\"",
    70: "5'10\"",
    71: "5'11\"",
    72: "6'0\"",
    73: "6'1\"",
    74: "6'2\"",
    75: "6'3\"",
    76: "6'4\"",
    77: "6'5\"",
    78: "6'6\"",
    79: "6'7\"",
    80: "6'8\"",
    81: "6'9\"",
    82: "6'10\"",
    83: "6'11\"",
    84: "7'0\""
  };


  const [value, setValue] = useState([
    convertHeightToInches(4.0),
    convertHeightToInches(7.0)
  ]);

  const handleSliderChange = (newValue) => {
    

    setValue(newValue);
    setFilter(
            [
              newValue[0] !== undefined ?Number(newValue[0]) : 1,
              newValue[1] !== undefined ?Number(newValue[1]) : 1,
            ],
            id
          );
    
  };



  const tipFormatter = (value) => {
    return convertToFeetInches(value);
  };


  return (
    <div style={{ margin: "20px" }}>
      <TooltipSlider
        range
        min={convertHeightToInches(4.1)}
        max={convertHeightToInches(7)}
        step={1}
        marks={marks}
        defaultValue={value}
        onChange={handleSliderChange}
        tipFormatter={tipFormatter}
        tipProps={{ visible: true }}
      />
    </div>
  );
  
}
function WeightRangeColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max values of the column
  // eslint-disable-next-line
  const [min, max] = React.useMemo(() => {
    const columnValues = preFilteredRows.map((row) => row.values[id]);
    return [Math.min(...columnValues), Math.max(...columnValues)];
  }, [id]);

  // Update the filter value when the slider value changes
  const handleSliderChange = (value) => {
    setFilter(
      [
        value.min !== undefined ? value.min : 1,
        value.max !== undefined ? value.max : max,
      ],
      id
    );
  };

  // Render the input range slider
  return (
    <div className="mb-4">
      <InputRange
        minValue={1}
        maxValue={max}
        value={{
          min: filterValue[0] !== undefined ? filterValue[0] : 1,
          max: filterValue[1] !== undefined ? filterValue[1] : max,
        }}
        onChange={(value) => handleSliderChange(value)}
      />
    </div>
  );
}
function NumberRangeColumnFilter({
  column: { filterValue = [], setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max values of the column
  // eslint-disable-next-line
  const [min, max] = React.useMemo(() => {
    const columnValues = preFilteredRows.map((row) => row.values[id]);
    return [Math.min(...columnValues), Math.max(...columnValues)];
  }, [id, preFilteredRows]);

  // Update the filter value when the slider value changes
  const handleSliderChange = (value) => {
    setFilter(
      [
        value.min !== undefined ? value.min : 1,
        value.max !== undefined ? value.max : 100,
      ],
      id
    );
  };

  // Render the input range slider
  return (
    <div className="mb-4">
      <InputRange
        minValue={1}
        maxValue={100}
        value={{
          min: filterValue[0] !== undefined ? filterValue[0] : 1,
          max: filterValue[1] !== undefined ? filterValue[1] : 100,
        }}
        onChange={(value) => handleSliderChange(value)}
      />
    </div>
  );
}
const AgeRangeFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const [min, max] = React.useMemo(() => {
    const columnValues = preFilteredRows.map((row) => {
      const { age_range_from, age_range_to } = row.values[id];
      return [parseInt(age_range_from), parseInt(age_range_to)];
    });
    const flattenedValues = columnValues.flat();
    return [Math.min(...flattenedValues), Math.max(...flattenedValues)];
  }, [id]);

  const handleSliderChange = (value) => {
    setFilter(
      {
        age_range_from: value?.min?.toString(),
        age_range_to: value?.max?.toString(),
      },
      id
    );
  };

  return (
    <div className="my-4">
      <InputRange
        minValue={1}
        maxValue={100}
        formatLabel={(value) => value?.toString()}
        value={{
          min:
              filterValue &&
              filterValue.age_range_from &&
              !isNaN(parseInt(filterValue.age_range_from))
                  ? parseInt(filterValue.age_range_from)
                  : min,
          max:
              filterValue &&
              filterValue.age_range_to &&
              !isNaN(parseInt(filterValue.age_range_to))
                  ? parseInt(filterValue.age_range_to)
                  : max,
      }}
        onChange={(value) => handleSliderChange(value)}
      />
    </div>
  );
};
function SelectCastingFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const allOptions = preFilteredRows.flatMap((row) => row.values[id]);
    const uniqueOptions = [...new Set(allOptions)];
    const flatOptions = uniqueOptions.flatMap((option) => option.split(", "));
    return [...new Set(flatOptions)];
  }, [id, preFilteredRows]);

  return (
    <select
      className="form-control   "
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}




const CreateCasting = () => {
  const disptach = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState(null);
  const [castingList, setCastingList] = useState([]);
  const [casting, setCasting] = useState([]);
  const [show, setShow] = useState(false);
  const [ClientName, setClientName] = useState("");
  const [CastingDirector, setCastingDirector] = useState("");
  const [Title, setTitle] = useState("");
  const [S_Date, setS_date] = useState("");
  const [E_Date, setE_date] = useState("");
  const [Summary, setSummary] = useState("");
  const [loader, setLoader] = useState(false);
  const [rowUpdates, setRowUpdates] = useState([]); // Batched row updates


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const navigate = useNavigate();



  // coloumns
  const COLUMNS = [
    {
      Header: "",
      Footer: "",
      accessor: "id",
      id: "checkbox",
      Cell: ({ row }) => {
        const { id } = row.original;
        return (
          <div className="d-flex justify-content-around align-items-center w-50">
            <div>
              <input
                type="checkbox"
                className="customCheckbox"
                id={`my-checkbox ${id}`}
                checked={isSelected(id)}
                onChange={() =>{
                  // updateRow(row.index,row.original)
                  toggleRow(id)
                }
                }
              />
              <label
                for={`my-checkbox ${id}`}
                className="customCheckboxLabel"
              ></label>
              {
                isSelected(id)  ? true :false
              }
            </div>

          </div>
        );
      },
      // Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      id: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "",
      Footer: "",
      accessor: "images",
      id: "images",
      Cell: ({ value }) => {
        return (
          <>
            {value !== undefined && value?.length !== 0 ? (
              <div className="actorList-bx">
                <div id="carouselExampleControls" className="carousel slide">
                  <div className="carousel-inner">
                    <TableSlider images={value} />
                  </div>
                </div>
                <div></div>
              </div>
            ) : (
              <div className="actorList-bx text-center">
                <p className="mx-auto">no image found</p>
              </div>
            )}
          </>
        );
      },
      // Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "name",
      Footer: "name",
      accessor: "full_name",
      id: "full_name",
      Cell: ({ value }) => {
        return <h3>{value}</h3>;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "gender",
      Footer: "gender",
      accessor: "gender",
      id: "gender",
      Filter: GenderSelectColumnFilter,
      filter: "exact"
    },

    {
      Header: "ethnicity",
      Footer: "ethnicity",
      accessor: "all_ethnicities",
      id: "all_ethnicities",
      Cell: ({ value }) => value.map((item) => item).join(", "),
      Filter: SelectCastingFilter,
    },
    {
      Header: "eye colour",
      Footer: "eye colour",
      accessor: "eye_color",
      id: "eye_color",
      Filter: eyeColorSelectColumnFilter,
      filter: "exact"

    },
    {
      Header: "hair colour",
      Footer: "hair colour",
      accessor: "hair_color",
      id: "hair_color",
      Filter: hairColorSelectColumnFilter,
      filter: "exact"

    },
    {
      Header: "unions",
      Footer: "unions",
      accessor: "unions",
      id: "unions",
      Filter: unionsSelectColumnFilter,
      filter: "exact"

    },
    {
      Header: "height",
      Footer: "height",
      accessor: "height",
      Filter: HeightRangeColumnFilter,
      filter: "between",
      id: "height",
    },
    {
      Header: "weight",
      Footer: "weight",
      accessor: "weight",
      id: "weight",
      Filter: WeightRangeColumnFilter,
      filter: "between",
      width: "30%",
      show: false,
    },
    {
      Header: "Age Range",
      Footer: "Age Range",
      id: "ages",
      accessor: ({ age_range_from, age_range_to }) => ({ age_range_from, age_range_to }),
      filter: (rows, id, filterValue) => {
        return rows.filter(row => {

          const { age_range_from, age_range_to } = row.values[id];
          if (!age_range_from || !age_range_to) {
            return true;
          }
          return (
            Number(age_range_from) <= Number(filterValue.age_range_to) &&
            Number(age_range_to) >= Number(filterValue.age_range_from)
          );
        });
      },
      Filter: AgeRangeFilter,
    },
    {
      Header: "Action",
      Footer: "Action",
      id: "action",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex">
          <Link
            to={`/actorDetails/${value}`}
            className="btn btn-success shadow btn-xs sharp ms-1"
          >
            <i className="fas fa-eye" />
          </Link>
        </div>
      ),
      disableFilters: true,
    },
  ];
  const isSelected = (id) => (selectedRows.includes(id) ? true : undefined);
  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS, [data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;

  const { pageIndex } = state;

  const token = localStorage.getItem("accesstoken");

  // getting all actor List from API
  const actorList = async () => {
    setLoader(true)
    setErrors(null);
    try {
      var config = {
        method: "get",
        url: `${Base_Api}/api/actors`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      const newArray = response?.data?.data?.map((obj) => ({
        ...obj,
        all_casting: obj.casting_list.map((item) => item.casting_list.title),
        all_ethnicities: obj.actor_ethnicities.map((item) => item.ethnicities),
        height: convertHeightToInches(obj.height),
      }));
      setData(newArray);

      setLoader(false)
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
      setLoader(false)
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  const user_id = localStorage.getItem("user_id");

  // all casting list
  const getAllCastingList = async () => {
    setErrors(null);
    setSelectedRows([]);
    try {
      var config = {
        method: "get",
        url: `${Base_Api}/api/user/castinglist/${user_id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      if (response?.data?.data?.length !== 0)
        setCastingList(
          response?.data?.data?.map((e) => ({
            value: e.id,
            label: (
              <div className="d-flex justify-content-between">
                <span>{e?.client_name}</span>
              </div>
            ),
          }))
        );
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  // adding actor into castig list
  const addActorToCastingList = async () => {
    setErrors(null);
    try {
      if (selectedRows.length === 0)
        throw new Error("please select casting first");
      var formdata = new FormData();
      formdata.append("casting_list_id", casting.value);
      selectedRows.map((e) => formdata.append("actor_id[]", e));
      formdata.append("creator", "admin");
      formdata.append("creator_id", "1");

      var config = {
        method: "post",
        url: `${Base_Api}/api/user/casting_list_actors`,
        data: formdata,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      if (response?.data?.status) {
        toast.success(`✔️ ${response.data.response}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        actorListNotInCasting(casting.value);
      } else {
        throw new Error("Failed to add");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (err?.response?.status === 422) {
        toast.error(err?.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(err.response.data.errors);
        const error = [];
        key.forEach((e) => {
          error.push(err.response.data.errors[e][0]);
        });
        return setErrors(error);
      }
    }
  };

  // fetching actor which are not in casting list
  const actorListNotInCasting = async (id) => {
    setErrors(null);
    setSelectedRows([]);
    setLoader(true)
    try {
      var config = {
        method: "get",
        url: `${Base_Api}/api/user/actors_doesnthave_castinglist/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      const newArray = response?.data?.data?.map((obj) => ({
        ...obj,
        all_casting: obj.casting_list.map((item) => item.casting_list.title),
        all_ethnicities: obj.actor_ethnicities.map((item) => item.ethnicities),
        height: convertHeightToInches(obj.height),  
      }));
      setData(newArray);
    setLoader(false)

    } catch (error) {
    setLoader(false)
      console.log(error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  //add new CastingList

  const addnewCastingList = async () => {
    setErrors(null);
    setLoader(true);
    try {
      var formdata = new FormData();
      formdata.append("client_name", ClientName);
      formdata.append("casting_director", CastingDirector);
      formdata.append("title", Title);
      formdata.append("start_date", S_Date);
      formdata.append("end_date", E_Date);
      formdata.append("summary", Summary);
      formdata.append("creator", "user");
      formdata.append("creator_id", user_id);

      var config = {
        method: "post",
        url: `${Base_Api}/api/user/casting_lists`,
        data: formdata,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      setLoader(false);
      if (response?.data?.status === true) {
        setShow(false);
        toast.success("Casting Create Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      getAllCastingList();
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      if (error?.response?.status === 422) {
        setLoader(false);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        const key = Object.keys(error.response.data.errors);
        const err = [];
        key.forEach((e) => {
          err.push(error.response.data.errors[e][0]);
        });
        return setErrors(err);
      }
    }
  };

  // csv Configurations

  const toggleRow = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };
 
  useEffect(() => {
    actorList();
    getAllCastingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRow = (rowIndex, newData) => {
    setRowUpdates((prevRowUpdates) => [
      ...prevRowUpdates,
      { rowIndex, newData }
    ]);
  };  
  // useEffect(() => {
  //   if (rowUpdates.length > 0) {
  //     // Apply the batched updates to the data state
  //     const updatedData = [...data];
  //     rowUpdates.forEach(({ rowIndex, newData }) => {
  //       updatedData[rowIndex] = { ...updatedData[rowIndex], ...newData };
  //     });
  //     setData(updatedData);

  //     // Clear the rowUpdates array
  //     setRowUpdates([]);
  //   }
  // }, [rowUpdates, data]);

  return (
    <>
    {
      loader ? <LoadingOverlay active={loader} spinner text="Loading your content..." />
      :
      <>
      <div style={{ overflow: "hidden" }}>
        <section
          class="hero-wrap"
          style={{ backgroundImage: "url('/images/bg_3.jpg')" }}
          data-stellar-background-ratio="0.5"
        >
          <div class="overlay"></div>
          <DetailHeader />
          <div class="container">
            <div
              class="row no-gutters text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div class="col-md-8 text-center">
                <p class="breadcrumbs">
                </p>
                <h1 class="mb-5 bread text-uppercase ">Create Casting</h1>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- END slider --> */}
        <div className="card">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card-header">
                <h2 className="card-title text-center py-3">Actor List</h2>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <div className="mx-2 my-auto w-100 mb-2">
                    <Select
                      placeholder="Select Casting List"
                      options={castingList}
                      value={casting}
                      isClearable
                      onChange={(e) => {
                        if (e !== null) {
                          actorListNotInCasting(e.value);
                          setCasting(e);
                          setSelectedRows([]);
                        } else {
                            setCasting([])
                          actorList();
                        }
                      }}
                    />

                    {castingList.length === 0 && (
                      <p className="text-danger">No Casting List Found</p>
                    )}
                  </div>

                  <button
                    className="btn btn-dark mx-2 w-100 mt-5"
                    onClick={addActorToCastingList}
                  >
                    Add into casting list
                  </button>

                  <Button className="btn btn-dark mx-2 w-100 my-2"  onClick={()=>{
                    if(selectedRows.length >0){

                      disptach(setActorData(data?.filter((row) => selectedRows.includes(row.id))))
                      navigate('/actorpdf')
                    }else{
                      toast.error("Kindly select some actor to print")
                    }
                  }
                  } 
                     >
                  Print 
                  </Button>

                  <button
                    className="btn btn-dark mx-2 w-100 mb-5"
                    onClick={handleShow}
                  >
                    Add New casting list
                  </button>
                </div>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-center filter-container"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      if (column.id === "checkbox") return null;
                      if (column.id === "id") return null;
                      if (column.id === "images") return null;
                      if (column.id === "action") return null;

                      return (
                        <div
                          className="filter-item"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      );
                    })}
                  </tr>
                ))}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="card-body">
                {errors && (
                  <Alert variant={"danger"}>
                    <ul>
                      {errors.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </Alert>
                )}
                <div className="" style={{ position: "relative" }}>
                  {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                  <table
                    {...getTableProps()}
                    className=""
                    style={{ marginBottom: "5rem" }}
                  >
                    <thead></thead>
                    <tbody {...getTableBodyProps()} className="grid-container ">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="grid-item " >
                            {row.cells.map((cell) => {
                              switch (cell.column.id) {
                                case "id":
                                  return null;
                                case "weight":
                                  return null;
                                case "height":
                                  return null;
                                // case "email":
                                //   return null;
                                // case "address":
                                //   return null;
                                case "all_casting":
                                  return null;
                                case "ethnicities":
                                  return null;
                                  case "all_ethnicities":
                                    return null;
                                case "eye_color":
                                  return null;
                                case "gender":
                                  return null;
                                case "hair_color":
                                  return null;
                                case "hair_length":
                                  return null;
                                case "unions":
                                  return null;
                                case "age_range":
                                  return null;
                                  case "ages":
                                return null;

                                default:
                                  <td
                                    className="text-center"
                                    {...cell.getCellProps()}
                                  >
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>;
                                  break;
                              }
                              return (
                                <td
                                  className="text-center w-100"
                                  {...cell.getCellProps()}
                                >
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around align-items-center">
            <div className="row w-100 align-items-center justify-content-center">
              <div className="col-md-4 mb-sm-2 text-sm-center">
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </div>
              <div className="text-center col-md-4 mb-sm-2 ">
                <div className="filter-pagination  mt-3">
                  <button
                    className=" previous-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <button
                    className="next-button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <button
                    className=" next-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
              <div className="table-index col-md-4">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}

      <Modal show={show} onHide={handleClose}>
        <LoadingOverlay active={loader} spinner text="Loading your content...">
          <Modal.Header>
            <Modal.Title>Add New Casting List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-6">
                <label className="mt-2">Client Name</label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Client Name"
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Casting Director</label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Casting Director"
                  onChange={(e) => setCastingDirector(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Title</label>
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2">Start Date</label>
                <input
                  type={"date"}
                  className="form-control"
                  placeholder="Enter Start Date"
                  onChange={(e) => setS_date(e.target.value)}
                />
              </div>
              <div className="col-12 Col-md-6">
                <label className="mt-2">End Date</label>
                <input
                  type={"date"}
                  className="form-control"
                  placeholder="Enter End Date"
                  onChange={(e) => setE_date(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-12">
                <label className="mt-2">Summary</label>
                <textarea
                  rows={5}
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Summary"
                  onChange={(e) => setSummary(e.target.value)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={addnewCastingList}>
              Send
            </Button>
          </Modal.Footer>
        </LoadingOverlay>
      </Modal>
    </>
    }
    </>

  );
};

export default CreateCasting;
