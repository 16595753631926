import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DetailHeader from "../../components/Header/DetailHeader";

const SearchModal = () => {
  const [Image, setImage] = useState([]);

  const state = useSelector((state) => state);
  // console.log(state, "saerch");

  const func = useCallback(() => {
    // console.log(state,"redux");
    setImage(state?.SearchData?.data?.map((e) => e?.actor?.images[0]?.image));
  },[state?.SearchData]);

  let uniqueArr = state?.SearchData?.data?.filter((obj, index, self) => {
    console.log(self)
    return index === self.findIndex((t) => (
      t.actor_id === obj.actor_id
      ));
    });
    console.log( uniqueArr,"uniqueArr")


  useEffect(() => {
    func();
  }, [func]);
 

  const navigate = useNavigate();

  const accesstoken = localStorage.getItem("accesstoken");
  //protected routing

  useEffect(() => {
    if (accesstoken) {
      navigate(`/searchresult`);
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section
        class="hero-wrap"
        style={{ backgroundImage: "url('/images/bg_2.jpg')" }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <DetailHeader />
        <div class="container">
          <div
            class="row no-gutters text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div class="col-md-8 text-center">
              <p class="breadcrumbs">
                {/* <span class="mr-2">
                  <a href="/">Home</a>
                </span>{" "}
                <span class="mr-2">Model</span> <span>Search Details</span> */}
              </p>
              <h1 class="mb-5 bread text-uppercase">Search Details</h1>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END slider --> */}

      {state.SearchData.data.length === 0 ? (
        <>
          <h2 className="text-center p-5">No Result Found</h2>
        </>
      ) : (
        <>
          <section className="ftco-section-2">
            <div className="container-fluid">
              <div className="row d-flex no-gutters">

                {state?.SearchData?.searchBy === "actor" ? 
                <>
                 {uniqueArr.map((e, i) => (
                  <>
                    <div
                      className="col-md-3 model-entry"
                      onClick={() => navigate("/actorDetails/" + e?.actor?.id)}
                    >
                      <div
                        className="model-img"
                        alt="no image found"
                        // style={{
                        //   backgroundImage: `url(${Image[i]})`,
                        // }}
                      >
                        <img className="model-img" src={Image[i]} alt="not found"/>
                        <div className="name">
                          <h3 className="text-dark">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                        </div>
                        <div className="text">
                          <h3 className="text-white">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                          <div className="d-flex models-info">
                            <div className="pr-md-3">
                              <p>Height</p>
                              <span>{e?.actor?.height}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>Weight</p>
                              <span>{e?.actor?.weight}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair length</p>
                              <span>{e?.actor?.hair_length}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair color</p>
                              <span>{e?.actor?.hair_color}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>eye color</p>
                              <span>{e?.actor?.eye_color}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                </>  
              : state?.SearchData?.searchBy === "casting" ?
              <>
                {state?.SearchData?.data?.map((e, i) => (
                  <>
                    <div
                      className="col-md-3 model-entry"
                      onClick={() => navigate("/actorDetails/" + e?.actor?.id)}
                    >
                      <div
                        className="model-img"
                        alt="no image found"
                        // style={{
                        //   backgroundImage: `url(${Image[i]})`,
                        // }}
                      >
                        <img className="model-img" src={Image[i]} alt="not found"/>
                        <div className="name">
                          <h3 className="text-dark">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                        </div>
                        <div className="text">
                          <h3 className="text-white">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                          <div className="d-flex models-info">
                            <div className="pr-md-3">
                              <p>Height</p>
                              <span>{e?.actor?.height}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>Weight</p>
                              <span>{e?.actor?.weight}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair length</p>
                              <span>{e?.actor?.hair_length}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair color</p>
                              <span>{e?.actor?.hair_color}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>eye color</p>
                              <span>{e?.actor?.eye_color}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                </>
                : state?.SearchData?.searchBy === "both"?
                <>
                {state?.SearchData?.data?.map((e, i) => (
                  <>
                    <div
                      className="col-md-3 model-entry"
                      onClick={() => navigate("/actorDetails/" + e?.actor?.id)}
                    >
                      <div
                        className="model-img"
                        alt="no image found"
                        // style={{
                        //   backgroundImage: `url(${Image[i]})`,
                        // }}
                      >
                        <img className="model-img" src={Image[i]} alt="not found"/>
                        <div className="name">
                          <h3 className="text-dark">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                        </div>
                        <div className="text">
                          <h3 className="text-white">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h3>
                          <div className="d-flex models-info">
                            <div className="pr-md-3">
                              <p>Height</p>
                              <span>{e?.actor?.height}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>Weight</p>
                              <span>{e?.actor?.weight}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair length</p>
                              <span>{e?.actor?.hair_length}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>hair color</p>
                              <span>{e?.actor?.hair_color}</span>
                            </div>
                            <div className="pr-md-3">
                              <p>eye color</p>
                              <span>{e?.actor?.eye_color}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                </>

             : null }
                
              </div>
            </div>
          </section>
        </>
      )}
      
    </>
  );
};

export default SearchModal;
