import "./App.css";
import "aos/dist/aos.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Home/Auth/Login/Login";
import Register from "./Pages/Home/Auth/Login/Register/Register";
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import SearchModal from "./Pages/Home/SearchModal";
import Casting from "./Pages/Home/Casting/Casting";
import ActorDetails from "./Pages/Home/ActorDetails";
import CastingDetail from "./Pages/Home/Casting/CastingDetail";
import CreateCasting from "./Pages/Home/Casting/CreateCasting";
import ActorPDF from "./Pages/ActorPDF";

import { useState } from "react";
import About from "./Pages/Home/About/About";

function App() {
  const [Loader, setLoader] = useState(true);

  setTimeout(() => {
    setLoader(false);
  }, 1500);

  return (
    <>
      {Loader ? (
        <>
          <div
            style={{
              background: 'url("/images/loader.gif")50% 50% no-repeat',
              position: "fixed",
              left: "0px",
              top: "0px",
              width: "100%",
              height: "100%",
              zIndex: "9999",
              opacity: "1",
            }}
          ></div>
        </>
      ) : (
        <>
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route
                  path="/actorDetails/:id"
                  element={<ActorDetails />}
                ></Route>
                <Route path="/searchresult" element={<SearchModal />}></Route>
                <Route path="/casting" element={<Casting />}></Route>
                <Route
                  path="/castingdetail/:id"
                  element={<CastingDetail />}
                ></Route>
                <Route
                  path="/createcasting"
                  element={<CreateCasting />}
                ></Route>
                <Route
                  path="/about"
                  element={<About />}
                ></Route>
                <Route 
                path="/Actorpdf"
                element={<ActorPDF />}
                ></Route>
              </Routes>
            </BrowserRouter>
          </Provider>

        </>
      )}
    </>
  );
}

export default App;
