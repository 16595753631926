import { combineReducers } from 'redux'
import SendData from './Store/Slices/SendData'
import SearchData from './Store/Slices/SearchData'
import ActorData from './Store/Slices/ActorData'


const reducer = combineReducers({
  SendData,
  SearchData,
  ActorData
  })

  export default reducer