/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../../Home/style.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { setSession } from "../../../../../utlilites/jwt";
import axios from "../../../../../utlilites/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
const SignupSchema = Yup.object().shape({
  fname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  username: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string()
  .min(8, "Password must be 8 characters long")
  .matches(/[0-9]/, "Password requires a number")
  .matches(/[a-z]/, "Password requires a lowercase letter")
  .matches(/[A-Z]/, "Password requires an uppercase letter")
  .matches(/[^\w]/, "Password requires a symbol"),
  phone_one: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(12, "Please enter 12 digits")
  .required("Required"),
  phone_two: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(12, "Please enter 12 digits")
  .required("Required"),
  company: Yup.string().required("Required"),
  fax: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  province: Yup.string().required("Required"),
  postal_code: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
});

const [, setLoader] = useState(false)

const Navigate = useNavigate();

    const signupfunc = async (V) => {
        setLoader(true);
        try {
         const response = await axios.post("/api/users/store", V);
        console.log(response, "workig");
         const { message, status, token } = response.data;
           console.log(message, "message");
           if (status === true) {
             setSession(token);
             toast.success("Register Successfully");
             Navigate("/");
           } else {
            setLoader(false)
           }
        } catch (errors) {
           setLoader(false);
           console.log(errors.errors[0]);
           toast.success(errors.errors[0]);
         }
       };


  return (
    <>
      <section className="vh-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-black">
              <div className="px-5 ms-xl-4">
                <i
                  className="fas fa-crow fa-2x me-3 pt-5 mt-xl-4"
                  style={{ color: "#709085" }}
                ></i>
                  <Link to={"/"} className="d-flex justify-content-start  align-items-center">
                  {/* <img src={"/images/logo1.png"} alt="logo" className="logo" /> */}
                  <h3 className=" mr-3">Bry</h3>  {" "}
                   <h3 className="">Talent</h3>
                </Link>
              </div>

              <div className="d-flex align-items-center mt-5 px-5 ms-xl-4  pt-xl-0 mt-xl-n5">
                <Formik
                  initialValues={{
                    fname: "",
                    lname: "",
                    email: "",
                    username: "",
                    password: "",
                    phone_one: "",
                    phone_two: "",
                    company: "",
                    fax: "",
                    website: "",
                    address: "",
                    city: "",
                    province: "",
                    postal_code: "",
                    country: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    console.log(values)
                    // same shape as initial values

                    signupfunc(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <h3
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: "1px" }}
                      >
                        Sign Up
                      </h3>
                      {/* <!-- 2 column grid layout with text Fields for the first and last names --> */}
                      <div class="row">
                        <div class="col-md-6 mb-4">
                          <div class="form-outline">
                            <Field
                              type="text"
                              id="form3Example1"
                              class="form-control"
                              name="fname"
                            />
                            <label class="form-label" for="form3Example1">
                              First name
                            </label>
                            {errors.fname && touched.fname ? (
                              <div>{errors.fname}</div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6 mb-4">
                          <div class="form-outline">
                            <Field
                              type="text"
                              id="form3Example2"
                              class="form-control"
                              name="lname"
                            />
                            <label class="form-label" for="form3Example2">
                              Last name
                            </label>
                            {errors.lname && touched.lname ? (
                              <div>{errors.lname}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Email Field --> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="email"
                              id="form3Example3"
                              class="form-control"
                              name="email"
                            />
                            <label class="form-label" for="form3Example3">
                              Email address
                            </label>
                            {errors.email && touched.email ? (
                              <div>{errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="username"
                            />
                            <label class="form-label" for="form3Example3">
                              username
                            </label>
                            {errors.username && touched.username ? (
                              <div>{errors.username}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Password Field --> */}
                      <div class="form-outline mb-4">
                        <Field
                          type="password"
                          id="form3Example4"
                          class="form-control"
                          name="password"
                        />
                        <label class="form-label" for="form3Example4">
                          Password
                        </label>
                        {errors.password && touched.password ? (
                          <div>{errors.password}</div>
                        ) : null}
                      </div>

                      {/* <!-- Phone Field --> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="phone_one"
                            />
                            <label class="form-label" for="form3Example3">
                              Phone Number 1
                            </label>
                            {errors.phone_one && touched.phone_one ? (
                              <div>{errors.phone_one}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="phone_two"
                            />
                            <label class="form-label" for="form3Example3">
                              Phone Number 2
                            </label>
                            {errors.phone_two && touched.phone_two ? (
                              <div>{errors.phone_two}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <!-- company Field --> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="company"
                            />
                            <label class="form-label" for="form3Example3">
                              Company
                            </label>
                            {errors.company && touched.company ? (
                              <div>{errors.company}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="fax"
                            />
                            <label class="form-label" for="form3Example3">
                              Fax
                            </label>
                            {errors.fax && touched.fax ? (
                              <div>{errors.fax}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <!-- address Field --> */}
                      <div className="row">
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="website"
                            />
                            <label class="form-label" for="form3Example3">
                              Website
                            </label>
                            {errors.website && touched.website ? (
                              <div>{errors.website}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="address"
                            />
                            <label class="form-label" for="form3Example3">
                              Address
                            </label>
                            {errors.address && touched.address ? (
                              <div>{errors.address}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="city"
                            />
                            <label class="form-label" for="form3Example3">
                              City
                            </label>
                            {errors.city && touched.city ? (
                              <div>{errors.city}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="province"
                            />
                            <label class="form-label" for="form3Example3">
                              Province
                            </label>
                            {errors.province && touched.province ? (
                              <div>{errors.province}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="postal_code"
                            />
                            <label class="form-label" for="form3Example3">
                              Postal Code
                            </label>
                            {errors.postal_code && touched.postal_code ? (
                              <div>{errors.postal_code}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="form-outline mb-4">
                            <Field
                              type="text"
                              id="form3Example3"
                              class="form-control"
                              name="country"
                            />
                            <label class="form-label" for="form3Example3">
                            country
                            </label>
                            {errors.country && touched.country ? (
                              <div>{errors.country}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <!-- Submit button --> */}
                      <button type="submit" class="btn btn-dark btn-block mb-4" >
                        Sign up
                      </button>
                      <p>
                        Do you have already account?{" "}
                        <a href="/login" className="link-info">
                          Login
                        </a>
                      </p>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-sm-6 px-0 d-none d-sm-block">
              <img
                src="/images/image_2.jpg"
                alt="Login image"
                className="w-100 "
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  height: "180vh",
                  //   marginTop: "200px"
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
