/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {  Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../style.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import DetailHeader from "../../../components/Header/DetailHeader";
import { Base_Api } from "../../../config";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingOverlay from "react-loading-overlay";
import { setActorData } from "../../../Redux/Store/Slices/ActorData";
import { useDispatch } from "react-redux";

const CastingDetail = () => {
  const [loader, setLoader] = useState(false);
  const [Data, setData] = useState();
  const disptach = useDispatch()
  //casting List api
  const Navigate = useNavigate();
  const { id } = useParams();

  const token = localStorage.getItem("accesstoken");

  const castingdetail = useCallback(() => {
    setLoader(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/user/casting_lists/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "details");
        setData(response?.data?.data, "Details");
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  },[id,token])
  console.log("Details", Data);

  const deleteactorfromcasting = (id) =>{
    setLoader(true);

    var config = {
      method: "delete",
      url: `${Base_Api}/api/user/casting_list_actors/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "details");
        // setData(response?.data?.data, "Details");
        if(response?.data?.status === 1){
          castingdetail();
        }
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  }
  const convertHeightToInches = (height) => {
    const [feet, inches = 0] = height.toString().split('.').map(Number);
    return feet * 12 + inches;
  };
  
  

  useEffect(() => {
    castingdetail();
  }, [castingdetail]);
  return (
    <>
      <section
        class="hero-wrap"
        style={{ backgroundImage: "url('/images/bg_4.jpg')" }}
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <DetailHeader />
        <div class="container">
          <div
            class="row no-gutters text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div class="col-md-12 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">Home</a>
                </span>{" "}
                {/* <span class="mr-2">Casting Detail</span>{" "} */}
                {/* <span>Casting Detail</span> */}
              </p>
              <h1 class="mb-5 bread">Casting</h1>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END slider --> */}

      <div className="container mt-5 w-100 ">
        <div className="row">
          <div className="col-md-12 text-right mb-4">
            <button className="btn btn-primary" onClick={()=>{
                    console.log(Data?.actors?.map(e=>e.actor))
                    disptach(setActorData(Data?.actors?.map(e=>({...e.actor,height:convertHeightToInches(e.actor.height)}))))
                    Navigate('/actorpdf')
                  }}>
                    Print
                  </button>
          </div>
          <div className="col-lg-12">
        
         
            <Table striped bordered hover variant="dark">
                  <tr>
                    <th>Client</th>
                    <th>Name</th>
                    <th>Casting Director</th>
                    <th>Start Date</th>
                    <th>End Date </th>
                    <th>Summary</th>
                    <th>Total Actors In the List</th>


                  </tr>
                  <tr>
                    <td> {loader ? <Skeleton /> : Data?.id}.&nbsp;{loader ? <Skeleton /> : Data?.title}</td>
                    <td>{loader ? <Skeleton /> : Data?.client_name}</td>
                    <td>{loader ? <Skeleton /> : Data?.casting_director}</td>
                    <td>{loader ? <Skeleton /> : Data?.start_date}</td>
                    <td> {loader ? <Skeleton /> : Data?.end_date}</td>
                    <td>{loader ? <Skeleton count={2}/> : Data?.summary}</td>
                    <td>{loader ? <Skeleton count={2}/> : Data?.actors.length}</td>


                  </tr>
                </Table>
      
          </div>
          {/* <div className="col-lg-6 d-flex align-items-center">
              <div className="p-5">
                <h2 className="mb-4">Our Tops Model's</h2>
                <p className="text-center">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia{" "}
                </p>
            </div>
          </div> */}
        
          <div className="col-lg-12 mt-5">
            <LoadingOverlay
                active={loader}
                spinner
                text="Loading your content..."
              >
            <div className="row" style={{height: "auto"}}>
              {Data?.actors?.map((e) => {
                return (
                  <>
                    <div
                      className="col-md-3 model-entry mb-5"
                    >
                      <div className="model-img" onClick={() => Navigate("/actorDetails/" + e?.actor?.id)}>
                        <Swiper
                          direction={"vertical"}
                          slidesPerView={1}
                          spaceBetween={30}
                          loop={true}
                          centeredSlides={true}
                          autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                          }}
                          pagination={{
                            clickable: true,
                          }}
                          navigation={true}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="mySwiper"
                        >
                          {e?.actor?.images?.map((x) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <img src={x.image} alt="no image found" />
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                        <div className="name bg-dark position-relative w-100">
                          <h2 className="text-white text-center ">
                            {e?.actor?.fname} {e?.actor?.lname}
                          </h2>
                        </div>
                    <button className="btn btn-dark w-100 mt-2 position-relative" onClick={()=>deleteactorfromcasting(e?.id)}>Delete Actor from this casting</button>
                    </div>
                  </>
                );
              })}
            </div>
                </LoadingOverlay>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default CastingDetail;
