import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import { Base_Api } from "../../config";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DetailHeader from "../../components/Header/DetailHeader";

const ActorDetails = () => {
  const [Details, setDetails] = useState();
  const [Desc, setDesc] = useState("");
  const [ ,setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const { id } = useParams();

  const token = localStorage.getItem("accesstoken");

  const castingDetails = useCallback(() => {
    setLoader(true);

    var config = {
      method: "get",
      url: `${Base_Api}/api/actors/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "details");
        setDetails(response?.data?.data, "Details");
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  },[id,token])
  console.log("Details", Details);

  useEffect(() => {
    castingDetails();
  }, [castingDetails]);

  const state = useSelector((state) => state);
  console.log(state, "saerch");
  const navigate = useNavigate();

  //ccontact api

  const casting_lists_id = localStorage.getItem("castinglist_id");
  const contactapi = () => {
    setLoader(true);

    const contactdata = new FormData();
    contactdata.append("description", Desc);

    var config = {
      method: "post",
      url: `${Base_Api}/api/user/contact_actors/${casting_lists_id}/${id}`,
      data: contactdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response, "contact api");
        toast.success("message Sent Successfully");
        setShow(false)
        // setDetails(response, "Details");
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  };

  const accesstoken = localStorage.getItem("accesstoken");
  //protected routing

  useEffect(() => {
    if (accesstoken) {
      navigate(`/actorDetails/${id}`);
    } else {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // function calculateAge(dateOfBirth) {
  //   // Split date string to get year, month, and day values
  //   var dob = dateOfBirth.split("-");
  //   var dobYear = parseInt(dob[0]);
  //   var dobMonth = parseInt(dob[1]) - 1;
  //   var dobDay = parseInt(dob[2]);
  
  //   // Calculate the age
  //   var today = new Date();
  //   var age = today.getFullYear() - dobYear;
  //   var monthDiff = today.getMonth() - dobMonth;
  //   if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDay)) {
  //     age--;
  //   }
  
  //   return age;
  // }
  return (
    <>
      <div className="page">
        <div id="colorlib-page">
          <section
            className="hero-wrap"
            style={{ backgroundImage: "url('/images/image_9.jpg')" }}
            data-stellar-background-ratio="0.5"
          >
            <div className="overlay"></div>
            <DetailHeader />
            <div className="container">
              <div
                className="row no-gutters text align-items-center justify-content-center"
                data-scrollax-parent="true"
              >
                <div className="col-md-8 text-center">
                  <p className="breadcrumbs">
                    <span className="mr-2">
                      {/* <a href="index.html">Home</a> */}
                    </span>{" "}
                    <span className="mr-2">
                      {/* <a href="model.html">Model</a> */}
                    </span>{" "}
                    {/* <span>Model Details</span> */}
                  </p>
                  <h1 className="mb-5 bread text-uppercase">Talent Casting</h1>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- END slider --> */}

          <section className="ftco-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="row model-detail d-mf-flex align-items-center">
                    <div className="col-md-6">
                      <div className="">
                        <Swiper
                          direction={"vertical"}
                          slidesPerView={1}
                          spaceBetween={30}
                          loop={true}
                          centeredSlides={true}
                          autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                          }}
                          pagination={{
                            clickable: true,
                          }}
                          navigation={true}
                          modules={[Autoplay, Pagination, Navigation]}
                          className="mySwiper"
                        >
                          {Details?.images?.map((e) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <img src={e.image} alt="" />
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5 model-info text-sm-center">
                      <h3 className="mb-4">
                        {Details?.fname} {Details?.lname}
                      </h3>
                      <p>
                        <span>Age range</span>{" "}
                        <span>
                        {Details?.age_range_from} - {Details?.age_range_to}
                        {/* {Details ?calculateAge(Details?.dob):null} */}
                        </span>
                      </p>
                      {/* <p>
                        <span>Date of Birth</span>{" "}
                        <span>
                          {Details?.dob}
                        </span>
                      </p> */}
                      <p>
                        <span>Height</span> <span>{Details?.height.replace(".","'")}" feet</span>
                      </p>
                      <p>
                        <span>Weight</span> <span>{Details?.weight} LBS</span>
                      </p>
                      <p>
                        <span>Hair Colour</span>{" "}
                        <span>{Details?.hair_color}</span>
                      </p>
                      <p>
                        <span>Hair Length</span>{" "}
                        <span>{Details?.hair_length}</span>
                      </p>
                      <p>
                        <span>Eye Colour</span> <span>{Details?.eye_color}</span>
                      </p>
                      <p>
                        <span>Gender</span> <span>{Details?.gender}</span>
                      </p>
                      {/* <p>
                        <span>Address</span> <span>{Details?.address}</span>
                      </p>
                      <p>
                        <span>City</span> <span>{Details?.city}</span>
                      </p> */}
                      <p>
                        <span>ethnicity</span> <span>{Details?.actor_ethnicities?.map(e=>e?.ethnicities)}</span>
                      </p>
                      {/* <p>
                        <span>Phone Number</span>{" "}
                        <span>
                          {Details?.phone_one} {Details?.phone_two}
                        </span>
                      </p> */}
                      {/* <p>
                        <span>Resume</span>{" "}
                        <span>
                          <a
                            href={Details?.resume}
                            target="_blank"
                            className="text-dark" rel="noreferrer"
                          >
                            {Details?.resume}
                          </a>
                        </span>
                      </p> */}
                      
                    </div>
                  </div>

                  <div className="row mt-5">
                    
                    
                    {Details?.images?.length <= 1 ?null:
                    Details?.images?.map((e) => {
                      return (
                        <>
                          <div className="col-md-6 col-lg-3">
                              <img
                                src={e?.image}
                                className=""
                                style={{
                                  width: "100%",
                                  height: "20vh",
                                  marginBottom: "20px",
                                  objectFit: "contain",
                                }}
                                alt="Colorlib Template"
                              />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <!-- Modal --> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label>Message</label>
            <textarea className="from-control" placeholder="Send message to actor" rows={8} cols={60} onChange={(e)=>setDesc(e.target.value)}></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={contactapi}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActorDetails;
